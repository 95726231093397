import React, { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import History from "./pages/History";
import Contact from "./pages/Contact";
import { languagesData } from "./data/languagesData";
import $ from "jquery";

function App() {
  const [languageObj, setLanguageObj] = useState(languagesData[0]); // Default olarak EN seçiyoruz.

  useEffect(() => {
    const checkLanguage = () => {
      const storedLanguage = localStorage.getItem("EriaWebsiteLanguage");
      if (storedLanguage) {
        const parsedLanguage = JSON.parse(storedLanguage);
        const foundLanguage = languagesData.find(
          (lang) => lang.languageTag === parsedLanguage.languageTag
        );
        if (foundLanguage) {
          setLanguageObj(foundLanguage);
          return;
        }
      }

      // Eğer localStorage'da dil yoksa, tarayıcı dilini kontrol et
      const browserLanguage = navigator.language || navigator.userLanguage;
      const foundLanguage = languagesData.find(
        (lang) => lang.languageCode === browserLanguage.split("-")[0]
      );
      if (foundLanguage) {
        setLanguageObj(foundLanguage);
      }
    };

    checkLanguage();
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Home languageObj={languageObj} setLanguageObj={setLanguageObj} />
          }
        />
        <Route
          path="/history"
          element={
            <History
              languageObj={languageObj}
              setLanguageObj={setLanguageObj}
            />
          }
        />
        <Route
          path="/contact"
          element={
            <Contact
              languageObj={languageObj}
              setLanguageObj={setLanguageObj}
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
