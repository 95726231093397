import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Languages from "../languages.json";
import { FaCheck } from "react-icons/fa";
import { languagesData } from "../data/languagesData";
import $ from "jquery";
import { ThreeDots } from "react-loader-spinner";

const History = ({ languageObj, setLanguageObj }) => {
  const [isSpinner, setIsSpinner] = useState(true);

  const location = useLocation();

  useEffect(() => {
    /*=============================================
      =       Menu sticky & Scroll to top          =
      =============================================*/
    var windows = $(window);
    var screenSize = windows.width();
    var sticky = $(".header-sticky");
    var $html = $("html");
    var $body = $("body");

    /*===========================================
      =            Submenu viewport position      =
      =============================================*/

    if ($(".has-children--multilevel-submenu").find(".submenu").length) {
      var elm = $(".has-children--multilevel-submenu").find(".submenu");

      elm.each(function () {
        var off = $(this).offset();
        var l = off.left;
        var w = $(this).width();
        var docH = windows.height();
        var docW = windows.width() - 10;
        var isEntirelyVisible = l + w <= docW;

        if (!isEntirelyVisible) {
          $(this).addClass("left");
        }
      });
    }
    /*==========================================
    =            mobile menu active            =
    ============================================*/

    // $("#mobile-menu-trigger").on("click", function () {
    //   $("#mobile-menu-overlay").addClass("active");
    //   $body.addClass("no-overflow");
    // });

    // $("#mobile-menu-close-trigger").on("click", function () {
    //   $("#mobile-menu-overlay").removeClass("active");
    //   $body.removeClass("no-overflow");
    // });

    // $(".offcanvas-navigation--onepage ul li a").on("click", function () {
    //   $("#mobile-menu-overlay").removeClass("active");
    //   $body.removeClass("no-overflow");
    // });

    /*Close When Click Outside*/
    // $body.on("click", function (e) {
    //   var $target = e.target;
    //   if (
    //     !$($target).is(".mobile-menu-overlay__inner") &&
    //     !$($target).parents().is(".mobile-menu-overlay__inner") &&
    //     !$($target).is("#mobile-menu-trigger") &&
    //     !$($target).is("#mobile-menu-trigger i")
    //   ) {
    //     $("#mobile-menu-overlay").removeClass("active");
    //     $body.removeClass("no-overflow");
    //   }
    //   if (
    //     !$($target).is(".search-overlay__inner") &&
    //     !$($target).parents().is(".search-overlay__inner") &&
    //     !$($target).is("#search-overlay-trigger") &&
    //     !$($target).is("#search-overlay-trigger i")
    //   ) {
    //     $("#search-overlay").removeClass("active");
    //     $body.removeClass("no-overflow");
    //   }
    // });

    /*===================================
    =           Menu Activeion          =
    ===================================*/
    var cururl = window.location.pathname;
    var curpage = cururl.substr(cururl.lastIndexOf("/") + 1);
    var hash = window.location.hash.substr(1);
    if ((curpage == "" || curpage == "/" || curpage == "admin") && hash == "") {
      //$("nav .navbar-nav > li:first-child").addClass("active");
    } else {
      $(".navigation-menu li").each(function () {
        $(this).removeClass("active");
      });
      if (hash != "")
        $(".navigation-menu li a[href*='" + hash + "']")
          .parents("li")
          .addClass("active");
      else
        $(".navigation-menu li a[href*='" + curpage + "']")
          .parents("li")
          .addClass("active");
    }

    /*=========================================
    =             open menu Active            =
    ===========================================*/
    $(".openmenu-trigger").on("click", function (e) {
      e.preventDefault();
      $(".open-menuberger-wrapper").addClass("is-visiable");
    });

    $(".page-close").on("click", function (e) {
      e.preventDefault();
      $(".open-menuberger-wrapper").removeClass("is-visiable");
    });

    /*=========================================
    =             open menu Active            =
    ===========================================*/
    $("#open-off-sidebar-trigger").on("click", function () {
      $("#page-oppen-off-sidebar-overlay").addClass("active");
      $body.addClass("no-overflow");
    });

    $("#menu-close-trigger").on("click", function () {
      $("#page-oppen-off-sidebar-overlay").removeClass("active");
      $body.removeClass("no-overflow");
    });

    /*=============================================
    =            search overlay active            =
    =============================================*/

    $("#search-overlay-trigger").on("click", function () {
      $("#search-overlay").addClass("active");
      $body.addClass("no-overflow");
    });

    $("#search-close-trigger").on("click", function () {
      $("#search-overlay").removeClass("active");
      $body.removeClass("no-overflow");
    });

    /*=============================================
    =            hidden icon active            =
    =============================================*/

    $("#hidden-icon-trigger").on("click", function () {
      $("#hidden-icon-wrapper").toggleClass("active");
    });

    /*=============================================
    =            newsletter popup active            =
    =============================================*/

    $("#newsletter-popup-close-trigger").on("click", function () {
      $("#newsletter-popup").removeClass("active");
    });

    /*=========================================
    =             open menu Active            =
    ===========================================*/
    $(".share-icon").on("click", function (e) {
      e.preventDefault();
      $(".entry-post-share").toggleClass("opened");
    });

    $body.on("click", function () {
      $(".entry-post-share").removeClass("opened");
    });
    // Prevent closing dropdown upon clicking inside the dropdown
    $(".entry-post-share").on("click", function (e) {
      e.stopPropagation();
    });

    /*=============================================
    =            offcanvas mobile menu            =
    =============================================*/
    var $offCanvasNav = $(".offcanvas-navigation"),
      $offCanvasNavSubMenu = $offCanvasNav.find(".sub-menu");

    /*Add Toggle Button With Off Canvas Sub Menu*/
    $offCanvasNavSubMenu
      .parent()
      .prepend('<span class="menu-expand"><i></i></span>');

    /*Close Off Canvas Sub Menu*/
    $offCanvasNavSubMenu.slideUp();

    /*Category Sub Menu Toggle*/
    $offCanvasNav.on("click", "li a, li .menu-expand", function (e) {
      var $this = $(this);
      if (
        $this
          .parent()
          .attr("class")
          .match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/) &&
        ($this.attr("href") === "#" || $this.hasClass("menu-expand"))
      ) {
        e.preventDefault();
        if ($this.siblings("ul:visible").length) {
          $this.parent("li").removeClass("active");
          $this.siblings("ul").slideUp();
        } else {
          $this.parent("li").addClass("active");
          $this
            .closest("li")
            .siblings("li")
            .removeClass("active")
            .find("li")
            .removeClass("active");
          $this.closest("li").siblings("li").find("ul:visible").slideUp();
          $this.siblings("ul").slideDown();
        }
      }
    });

    return () => {
      // Event listeners'ı temizlemek için
      $("#mobile-menu-trigger").off("click");
      $("#mobile-menu-close-trigger").off("click");
      $(".offcanvas-navigation--onepage ul li a").off("click");
      $body.off("click");

      $(".openmenu-trigger").off("click");
      $(".page-close").off("click");
      $("#open-off-sidebar-trigger").off("click");
      $("#menu-close-trigger").off("click");
      $("#search-overlay-trigger").off("click");
      $("#search-close-trigger").off("click");
      $("#hidden-icon-trigger").off("click");
      $("#newsletter-popup-close-trigger").off("click");
      $(".share-icon").off("click");
      $(".entry-post-share").off("click");
      $offCanvasNav.off("click", "li a, li .menu-expand");
    };
  }, []);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      console.log("element: ", element);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        console.log(
          "element: ",
          element.scrollIntoView({ behavior: "smooth" })
        );
      }
    }
  }, [location]);

  const handleLanguageChange = (newLanguage) => {
    setLanguageObj(newLanguage);
    localStorage.setItem("EriaWebsiteLanguage", JSON.stringify(newLanguage));
  };

  let headerProjectTitleSectoral =
    Languages[languageObj.languageCode].Header.headerProjectTitleSectoral;
  let headerProjectTitleSpecial =
    Languages[languageObj.languageCode].Header.headerProjectTitleSpecial;
  let headerEczaTrendSubtitle =
    Languages[languageObj.languageCode].Header.headerEczaTrendSubtitle;
  let headerKapadokyaBalloon =
    Languages[languageObj.languageCode].Header.headerKapadokyaBalloon;
  let headerKapadokyaBalloonSubtitle =
    Languages[languageObj.languageCode].Header.headerKapadokyaBalloonSubtitle;
  let headerCafestalkSubtitle =
    Languages[languageObj.languageCode].Header.headerCafestalkSubtitle;
  let headerEKVKKSubtitle =
    Languages[languageObj.languageCode].Header.headerEKVKKSubtitle;
  let headerHBYSSubtitle =
    Languages[languageObj.languageCode].Header.headerHBYSSubtitle;
  let headerEKOBSSubtitle =
    Languages[languageObj.languageCode].Header.headerEKOBSSubtitle;
  let headerSayacOkuma =
    Languages[languageObj.languageCode].Header.headerSayacOkuma;
  let headerSayacOkumaSubtitle =
    Languages[languageObj.languageCode].Header.headerSayacOkumaSubtitle;
  let headerTekstilBorsası =
    Languages[languageObj.languageCode].Header.headerTekstilBorsası;
  let headerTekstilBorsasıSubtitle =
    Languages[languageObj.languageCode].Header.headerTekstilBorsasıSubtitle;
  let headerDetailsButton =
    Languages[languageObj.languageCode].Header.headerDetailsButton;
  let headerGoToProject =
    Languages[languageObj.languageCode].Header.headerGoToProject;
  let headerHomePage =
    Languages[languageObj.languageCode].Header.headerHomePage;
  let headerAboutUs = Languages[languageObj.languageCode].Header.headerAboutUs;
  let headerProducts =
    Languages[languageObj.languageCode].Header.headerProducts;
  let headerContact = Languages[languageObj.languageCode].Header.headerContact;

  let historyBottomFooterSocial =
    Languages[languageObj.languageCode].History.historyBottomFooterSocial;
  let historyBottomFooterMobile =
    Languages[languageObj.languageCode].History.historyBottomFooterMobile;
  let historyBottomFooterPartner =
    Languages[languageObj.languageCode].History.historyBottomFooterPartner;

  let historyContactTitle =
    Languages[languageObj.languageCode].History.historyContactTitle;
  let historyContactCallNow =
    Languages[languageObj.languageCode].History.historyContactCallNow;
  let historyContactYourWant =
    Languages[languageObj.languageCode].History.historyContactYourWant;

  let historyUpTitle1 =
    Languages[languageObj.languageCode].History.historyUpTitle1;
  let historyUpTitle2 =
    Languages[languageObj.languageCode].History.historyUpTitle2;
  let historyUpSubtitle =
    Languages[languageObj.languageCode].History.historyUpSubtitle;

  let historyTitle2010 =
    Languages[languageObj.languageCode].History.historyTitle2010;
  let historyInfo2010 =
    Languages[languageObj.languageCode].History.historyInfo2010;
  let historyTitle2011 =
    Languages[languageObj.languageCode].History.historyTitle2011;
  let historyInfo2011 =
    Languages[languageObj.languageCode].History.historyInfo2011;
  let historyTitle2013 =
    Languages[languageObj.languageCode].History.historyTitle2013;
  let historyInfo2013 =
    Languages[languageObj.languageCode].History.historyInfo2013;
  let historyTitle2015 =
    Languages[languageObj.languageCode].History.historyTitle2015;
  let historyInfo2015 =
    Languages[languageObj.languageCode].History.historyInfo2015;
  let historyTitle2016 =
    Languages[languageObj.languageCode].History.historyTitle2016;
  let historyInfo2016 =
    Languages[languageObj.languageCode].History.historyInfo2016;
  let historyTitle2017 =
    Languages[languageObj.languageCode].History.historyTitle2017;
  let historyInfo2017 =
    Languages[languageObj.languageCode].History.historyInfo2017;
  let historyTitle2018 =
    Languages[languageObj.languageCode].History.historyTitle2018;
  let historyInfo2018 =
    Languages[languageObj.languageCode].History.historyInfo2018;
  let historyTitle2019 =
    Languages[languageObj.languageCode].History.historyTitle2019;
  let historyInfo2019 =
    Languages[languageObj.languageCode].History.historyInfo2019;
  let historyTitle2021 =
    Languages[languageObj.languageCode].History.historyTitle2021;
  let historyInfo2021 =
    Languages[languageObj.languageCode].History.historyInfo2021;

  let historyInfo2022 =
    Languages[languageObj.languageCode].History.historyInfo2022;
  let historyTitle2023 =
    Languages[languageObj.languageCode].History.historyTitle2023;
  let historyInfo2023 =
    Languages[languageObj.languageCode].History.historyInfo2023;

  let contactBottomFooterPartner =
    Languages[languageObj.languageCode].Contact.contactBottomFooterPartner;

  const [mobileMenu, setMobileMenu] = useState(false);

  const triggerMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  useEffect(() => {
    setIsSpinner(true);

    setTimeout(() => {
      setIsSpinner(false);
    }, 1000);

    setTimeout(() => {
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        console.log("element: ", element);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          console.log(
            "element: ",
            element.scrollIntoView({ behavior: "smooth" })
          );
        }
      }
    }, 1100);
  }, []);

  return (
    <>
      {/* <div className="preloader-activate preloader-active open_tm_preloader">
        <div className="preloader-area-wrap">
          <div className="spinner d-flex justify-content-center align-items-center h-100">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      </div> */}
      {isSpinner && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // veya ihtiyaca göre başka bir yükseklik
          }}
        >
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color={"rgb(54, 162, 235)"}
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}

      {!isSpinner && (
        <>
          {/*====================  header area ====================*/}
          <div className="header-area header-area--default">
            {/* Header Bottom Wrap Start */}
            <div className="header-bottom-wrap header-sticky">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="header default-menu-style position-relative">
                      {/* brand logo */}
                      <div className="header__logo">
                        <a href="/">
                          <img
                            src="assets/photos/eriateknolojiBlack.png"
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                      </div>
                      {/* header midle box  */}
                      <div className="header-midle-box">
                        <div className="header-bottom-wrap d-md-block d-none">
                          <div className="header-bottom-inner">
                            <div className="header-bottom-left-wrap">
                              {/* navigation menu */}
                              <div className="header__navigation d-none d-xl-block">
                                <nav className="navigation-menu primary--menu">
                                  <ul>
                                    <li className="has-children has-children--multilevel-submenu">
                                      <a href="/">
                                        <span>{headerHomePage}</span>
                                      </a>
                                    </li>
                                    <li className="has-children has-children--multilevel-submenu">
                                      <a>
                                        <span>{headerProducts}</span>
                                      </a>
                                      <ul className="submenu">
                                        <li>
                                          <a
                                            href="https://eczatrend.com/"
                                            target="_blank"
                                          >
                                            <span style={{ color: "gray" }}>
                                              EczaTrend
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#token">
                                            <span style={{ color: "gray" }}>
                                              {"TokenFlex"}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#lonca">
                                            <span style={{ color: "gray" }}>
                                              {historyTitle2023}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="http://camoballoon.com/"
                                            target="_blank"
                                          >
                                            <span style={{ color: "gray" }}>
                                              {headerKapadokyaBalloon}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="http://cafestalk.com/"
                                            target="_blank"
                                          >
                                            <span style={{ color: "gray" }}>
                                              Cafestalk
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#kvkk">
                                            <span style={{ color: "gray" }}>
                                              E-KVKK
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#hbys">
                                            <span style={{ color: "gray" }}>
                                              {headerHBYSSubtitle}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#ekobs">
                                            <span style={{ color: "gray" }}>
                                              {headerEKOBSSubtitle}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#sayac">
                                            <span style={{ color: "gray" }}>
                                              {headerSayacOkuma}
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li className="has-children has-children--multilevel-submenu">
                                      <a href="#/contact">
                                        <span>{headerContact}</span>
                                      </a>
                                    </li>
                                    <li className="has-children has-children--multilevel-submenu">
                                      <a>
                                        <img
                                          style={{ width: 30 }}
                                          src={languageObj.languageImage}
                                          alt={languageObj.languageName}
                                        />
                                        <span style={{ marginLeft: 5 }}>
                                          {languageObj.languageName}
                                        </span>
                                      </a>
                                      <ul className="submenu">
                                        {languagesData.map((item, index) => (
                                          <li
                                            key={index}
                                            onClick={() =>
                                              handleLanguageChange(item)
                                            }
                                          >
                                            <a>
                                              <img
                                                style={{ width: 30 }}
                                                src={item.languageImage}
                                                alt={item.languageName}
                                              />
                                              <span style={{ marginLeft: 5 }}>
                                                {item.languageName}
                                              </span>
                                              {item.languageTag ===
                                                languageObj.languageTag && (
                                                <FaCheck
                                                  color="#086AD8"
                                                  style={{ marginLeft: 5 }}
                                                />
                                              )}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* mobile menu */}
                      <div
                        className="mobile-navigation-icon d-block d-xl-none"
                        id="mobile-menu-trigger"
                        onClick={() => triggerMobileMenu()}
                      >
                        <i />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Header Bottom Wrap End */}
          </div>
          {/*====================  End of header area  ====================*/}
          {/* breadcrumb-area start */}
          <div
            className="breadcrumb-area"
            style={{
              backgroundImage: "url(assets/photos/urunlerson.jpg?v=6.0)",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="breadcrumb_box text-center"
                    style={{ color: "white" }}
                  >
                    {/* breadcrumb-list start */}
                    <ul className="breadcrumb-list">
                      <li
                        className="breadcrumb-item"
                        style={{ color: "white" }}
                      >
                        <a href="/" class="custom-link">
                          {headerHomePage}
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        style={{ color: "white" }}
                      >
                        {headerAboutUs}
                      </li>
                    </ul>
                    {/* breadcrumb-list end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* breadcrumb-area end */}
          <div id="main-wrapper">
            <div className="site-wrapper-reveal">
              {/*===========  our history wrapper  Start =============*/}
              <div className="our-history-wrapper section-space--ptb_100">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* section-title-wrap Start */}
                      <div className="section-title-wrap text-center section-space--mb_30">
                        <h3 className="heading mb-20">
                          <span className="text-color-primary">
                            {" "}
                            {historyUpTitle1}{" "}
                          </span>{" "}
                          {historyUpTitle2}{" "}
                        </h3>
                        <p className="title-dec-text">{historyUpSubtitle}</p>
                      </div>
                      {/* section-title-wrap Start */}
                    </div>
                  </div>
                  <div className="timeline-area section-space--pt_60">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="ht-timeline style-01">
                          <ul className="tm-timeline-list ht-animation-queue">
                            <li className="line" />
                            <li className="item animate">
                              <div className="dots">
                                <div className="middle-dot" />
                              </div>
                              <div className="row">
                                <div className="col-md-6 timeline-col left timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h2 className="year">2010</h2>
                                    </div>
                                    <div className="photo">
                                      <img
                                        src="assets/photos/ultrason.jpg"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {historyTitle2010}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2010}{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="item animate">
                              <div className="dots">
                                <div className="middle-dot" />
                              </div>
                              <div className="row" id="hbys">
                                <div className="col-md-6 timeline-col left timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h6 className="year"> 2011 </h6>
                                    </div>
                                    <br />
                                    <div className="photo">
                                      <img
                                        src="assets/photos/hbys.jpg"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {historyTitle2011}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2011}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="item animate">
                              <div className="dots">
                                <div className="middle-dot" />
                              </div>
                              <div className="row" id="sayac">
                                <div className="col-md-6 timeline-col left timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h6 className="year"> 2013 </h6>
                                    </div>
                                    <br />
                                    <div className="photo">
                                      <img
                                        src="assets/photos/yazilimm.jpg"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {historyTitle2013}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2013}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="item animate">
                              <div className="row" id="ekobs">
                                <div className="col-md-6 timeline-col right timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h6 className="year"> 2015 </h6>
                                    </div>
                                    <br />
                                    <div className="photo">
                                      <img
                                        src="assets/photos/bordro.jpg"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {historyTitle2015}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2015}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="item animate">
                              <div className="row" id="eczatrend">
                                <div className="col-md-6 timeline-col right timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h6 className="year"> 2016 </h6>
                                    </div>
                                    <br />
                                    <div className="photo">
                                      <img
                                        src="assets/photos/eczatrend.jpg"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {historyTitle2016}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2016}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="item animate">
                              <div className="row" id="balon">
                                <div className="col-md-6 timeline-col right timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h6 className="year"> 2017 </h6>
                                    </div>
                                    <br />
                                    <div className="photo">
                                      <img
                                        src="assets/photos/balonbig.png?v=2.0"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                        style={{ maxWidth: 450 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {historyTitle2017}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2017}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="item animate">
                              <div className="row">
                                <div className="col-md-6 timeline-col right timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h6 className="year"> 2018 </h6>
                                    </div>
                                    <br />
                                    <div className="photo">
                                      <img
                                        src="assets/photos/box.jpg"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {historyTitle2018}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2018}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="item animate">
                              <div className="row" id="cafestalk">
                                <div className="col-md-6 timeline-col right timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h6 className="year"> 2019 </h6>
                                    </div>
                                    <br />
                                    <div className="photo">
                                      <img
                                        src="assets/photos/cafestalk.jpg"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {historyTitle2019}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2019}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="item animate">
                              <div className="row" id="kvkk">
                                <div className="col-md-6 timeline-col right timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h6 className="year"> 2021 </h6>
                                    </div>
                                    <br />
                                    <div className="photo">
                                      <img
                                        src="assets/photos/kvkkbig.png"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {historyTitle2021}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2021}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="item animate">
                              <div className="row" id="token">
                                <div className="col-md-6 timeline-col right timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h6 className="year"> 2022 </h6>
                                    </div>
                                    <br />
                                    <div className="photo">
                                      <img
                                        src="assets/photos/token2.png?v=2.0"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {"TokenFlex"}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2022}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="item animate">
                              <div className="row" id="lonca">
                                <div className="col-md-6 timeline-col right timeline-feature">
                                  <div className="inner">
                                    <div className="date-wrap">
                                      <h6 className="year"> 2023 </h6>
                                    </div>
                                    <br />
                                    <div className="photo">
                                      <img
                                        src="assets/photos/lonca2.png?v=2.0"
                                        alt="timeline-image-01"
                                        className="img-fluid"
                                        style={{ maxHeight: 450 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 timeline-col right timeline-info">
                                  <div className="inner">
                                    <div className="content-wrap">
                                      <div className="content-body">
                                        <h6 className="heading">
                                          {historyTitle2023}
                                        </h6>
                                        <div className="text">
                                          {historyInfo2023}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*===========  feature-large-images-wrapper  End =============*/}
              {/*============ Contact Us Area Start =================*/}
              <div
                className="contact-us-area infotechno-contact-us-bg section-space--pt_100"
                id="callus"
              >
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-7">
                      <div className="image">
                        <img
                          className="img-fluid"
                          src="assets/photos/call.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 ml-auto">
                      <div className="contact-info style-two text-left">
                        <div className="contact-info-title-wrap text-center">
                          <div className="ht-star-rating lg-style">
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                            <span className="fa fa-star" />
                          </div>
                          <p className="sub-text">{historyContactTitle}</p>
                        </div>
                        <div className="contact-list-item">
                          <a
                            href="tel:190068668"
                            className="single-contact-list"
                          >
                            <div className="content-wrap">
                              <div className="content">
                                <div className="icon">
                                  <span className="fal fa-phone" />
                                </div>
                                <div className="main-content">
                                  <h6 className="heading">
                                    {historyContactCallNow}
                                  </h6>
                                  <div className="text">0352 224 60 68</div>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a
                            href="mailto:info@eriateknoloji.com"
                            className="single-contact-list"
                          >
                            <div className="content-wrap">
                              <div className="content">
                                <div className="icon">
                                  <span className="fal fa-envelope" />
                                </div>
                                <div className="main-content">
                                  <h6 className="heading">
                                    {historyContactYourWant}
                                  </h6>
                                  <div className="text">
                                    info@eriateknoloji.com
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*============ Contact Us Area End =================*/}
            </div>
            {/*====================  footer area ====================*/}
            <div
              id="contact"
              className="footer-area-wrapper reveal-footer bg-gray"
              style={{ position: "relative" }}
            >
              <div className="footer-area section-space--ptb_80">
                <div className="container">
                  <div className="row footer-widget-wrapper">
                    <div className="col-lg-4 col-md-6 col-sm-6 footer-widget">
                      <div className="footer-widget__logo mb-30">
                        <img
                          style={{ width: "50%", height: "50%" }}
                          src="assets/photos/eriateknolojiBlack.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <ul className="footer-widget__list">
                        <li>
                          Erciyes Teknopark 1. Bina No:65 Melikgazi KAYSERİ
                        </li>
                        <li>
                          <a
                            href="mailto:info@eriateknoloji.com"
                            className="hover-style-link"
                          >
                            info@eriateknoloji.com
                          </a>
                        </li>
                        <li>
                          <a
                            href="tel:0352 224 60 68"
                            className="hover-style-link text-black font-weight--bold"
                          >
                            0352 224 60 68
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                      <h6 className="footer-widget__title mb-20">
                        {historyBottomFooterSocial}
                      </h6>
                      <ul className="footer-widget__list">
                        <li>
                          <a href="https://www.facebook.com/EczaTrend/">
                            <img
                              src="assets/photos/facebook.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/eczatrend/?hl=tr">
                            <img
                              src="assets/photos/insta.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                      <h6 className="footer-widget__title mb-20">
                        {historyBottomFooterMobile}
                      </h6>
                      <ul className="footer-widget__list">
                        <li>
                          <a href="https://play.google.com/store/apps/developer?id=ERIA+Teknoloji">
                            <img
                              src="assets/images/icons/aeroland-button-google-play.jpg"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="https://apps.apple.com/us/developer/eria-teknoloji-yazilim-danismanlik-arge-ve-ticaret/id1189743730">
                            <img
                              src="assets/images/icons/aeroland-button-app-store.jpg"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                      <h6 className="footer-widget__title mb-20">
                        {contactBottomFooterPartner}
                      </h6>
                      <ul className="footer-widget__list">
                        <li>
                          <a href="https://www.iyzico.com/" target="_blank">
                            <img
                              style={{
                                width: 125,
                                maxWidth: 125,
                                height: 60,
                              }}
                              src="assets/photos/iyziko3.png?v=3.0"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="https://oderopay.com.tr/" target="_blank">
                            <img
                              style={{
                                width: 125,
                                maxWidth: 125,
                                height: 60,
                              }}
                              src="assets/photos/odero3.png?v=5.0"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.moka.com/" target="_blank">
                            <img
                              style={{
                                width: 125,
                                maxWidth: 125,
                                height: 60,
                              }}
                              src="assets/photos/moka.png?v=3.0"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-copyright-area section-space--pb_30">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-6 text-center text-md-left">
                      <span className="copyright-text">
                        © 2021 Eria Teknoloji.
                      </span>
                    </div>
                    <div className="col-md-6 text-center text-md-right">
                      <ul className="list ht-social-networks solid-rounded-icon">
                        <li className="item">
                          <img
                            src="assets/photos/cart.png"
                            className="img-fluid"
                            alt=""
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*====================  End of footer area  ====================*/}
          </div>
          {/*====================  scroll top ====================*/}
          <a href="#" className="scroll-top" id="scroll-top">
            <i className="arrow-top fal fa-long-arrow-up" />
            <i className="arrow-bottom fal fa-long-arrow-up" />
          </a>
          {/*====================  End of scroll top  ====================*/}
        </>
      )}

      {/*====================  mobile menu overlay ====================*/}
      <div
        className={
          mobileMenu ? "mobile-menu-overlay active" : "mobile-menu-overlay"
        }
        id="mobile-menu-overlay"
      >
        <div className="mobile-menu-overlay__inner">
          <div className="mobile-menu-overlay__header">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-6 col-8">
                  {/* logo */}
                  <div className="logo">
                    <a href="/">
                      <img
                        src="assets/photos/eriateknolojiBlack.png"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-4">
                  {/* mobile menu content */}
                  <div
                    className="mobile-menu-content text-right"
                    onClick={() => triggerMobileMenu()}
                  >
                    <span
                      className="mobile-navigation-close-icon"
                      id="mobile-menu-close-trigger"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-menu-overlay__body">
            <nav className="offcanvas-navigation">
              <ul>
                <li className="has-children">
                  <a href="/">{headerHomePage}</a>
                </li>
                <li className="has-children">
                  <a href="#/history">{headerProducts}</a>
                  <ul className="sub-menu">
                    <li>
                      <a href="https://eczatrend.com/" target="_blank">
                        <span>EczaTrend</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#token">
                        <span>{"TokenFlex"}</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#lonca">
                        <span>{historyTitle2023}</span>
                      </a>
                    </li>
                    <li>
                      <a href="http://camoballoon.com/" target="_blank">
                        <span>{headerKapadokyaBalloon}</span>
                      </a>
                    </li>
                    <li>
                      <a href="http://cafestalk.com/" target="_blank">
                        <span>Cafestalk</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#kvkk">
                        <span>E-KVKK</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#hbys">
                        <span>{headerHBYSSubtitle}</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#ekobs">
                        <span>{headerEKOBSSubtitle}</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#sayac">
                        <span>{headerSayacOkuma}</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="has-children">
                  <a href="#/contact">{headerContact}</a>
                </li>
                {languagesData.map((item, index) => (
                  <li key={index} onClick={() => handleLanguageChange(item)}>
                    <a>
                      <img
                        style={{ width: 30 }}
                        src={item.languageImage}
                        alt={item.languageName}
                      />
                      <span style={{ marginLeft: 5 }}>{item.languageName}</span>
                      {item.languageTag === languageObj.languageTag && (
                        <FaCheck
                          color={"rgb(255, 159, 64)"}
                          style={{ marginLeft: 5 }}
                        />
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {/*====================  End of mobile menu overlay  ====================*/}
      {/*====================  search overlay ====================*/}
      <div className="search-overlay" id="search-overlay">
        <div className="search-overlay__header">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6 ml-auto col-4">
                {/* search content */}
                <div className="search-content text-right">
                  <span
                    className="mobile-navigation-close-icon"
                    id="search-close-trigger"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-overlay__inner">
          <div className="search-overlay__body">
            <div className="search-overlay__form">
              <form action="#">
                <input type="text" placeholder="Search" />
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of search overlay  ====================*/}
    </>
  );
};

export default History;
