export const languagesData = [
  {
    languageName: "Türkçe",
    languageTag: "TR",
    languageCode: "tr",
    languageImage: require("../assets/turkey.png"),
  },
  {
    languageName: "Azərbaycan",
    languageTag: "AZ",
    languageCode: "az",
    languageImage: require("../assets/azerbaijan.png"),
  },
  {
    languageName: "English",
    languageTag: "EN",
    languageCode: "en",
    languageImage: require("../assets/usa.png"),
  },
  {
    languageName: "Deutsch",
    languageTag: "DE",
    languageCode: "de",
    languageImage: require("../assets/germany.png"),
  },
  {
    languageName: "Français",
    languageTag: "FR",
    languageCode: "fr",
    languageImage: require("../assets/france.png"),
  },
  {
    languageName: "Español",
    languageTag: "ES",
    languageCode: "es",
    languageImage: require("../assets/spain.png"),
  },
  {
    languageName: "Italiano",
    languageTag: "IT",
    languageCode: "it",
    languageImage: require("../assets/italy.png"),
  },
  {
    languageName: "Русский",
    languageTag: "RU",
    languageCode: "ru",
    languageImage: require("../assets/russia.png"),
  },
];
